import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from "@elastic/eui";
import NavButtons from "components/Software/Linux/NavButtons";
import BreadCrumbs from "components/Layout/BreadCrumbs";
import SEOHelmet from "components/Layout/SEOHelmet";
export const _frontmatter = {
  "title": "Frigate NVR - OpenCV, Tensorflow and MQTT",
  "path": "/Software/Linux/Frigate_NVR/",
  "dateChanged": "2023-01-19",
  "author": "Mike Polinowski",
  "excerpt": "A complete and local NVR designed for Home Assistant with AI object detection. Uses OpenCV and Tensorflow to perform realtime object detection locally for IP cameras.",
  "image": "./P_SearchThumb_Linux.png",
  "social": "/images/Search/P_SearchThumb_Linux.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Linux_Shinobi_white.webp",
  "chapter": "Software",
  "category": "smarthome",
  "type": "Frigate"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title="Frigate NVR - OpenCV, Tensorflow and MQTT" dateChanged="2023-01-19" author="Mike Polinowski" tag="INSTAR IP Camera" description="A complete and local NVR designed for Home Assistant with AI object detection. Uses OpenCV and Tensorflow to perform realtime object detection locally for IP cameras." image="/images/Search/P_SearchThumb_Linux.png" twitter="/images/Search/P_SearchThumb_Linux.webp" location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE="/de/Software/Linux/Frigate_NVR/" locationFR="/fr/Software/Linux/Frigate_NVR/" crumbLabel="Frigate" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "frigate-nvr---opencv-tensorflow-and-mqtt",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#frigate-nvr---opencv-tensorflow-and-mqtt",
        "aria-label": "frigate nvr   opencv tensorflow and mqtt permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Frigate NVR - OpenCV, Tensorflow and MQTT`}</h2>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#frigate-nvr---opencv-tensorflow-and-mqtt"
        }}>{`Frigate NVR - OpenCV, Tensorflow and MQTT`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#installation"
        }}>{`Installation`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#dependencies"
            }}>{`Dependencies`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#storage"
            }}>{`Storage`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#calculating-required-shm-size"
            }}>{`Calculating required shm-size`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#creating-a-config-file"
            }}>{`Creating a config file`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#configure-the-mqtt-server"
                }}>{`Configure the MQTT server`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#configure-your-cameras"
                }}>{`Configure your Cameras`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#downloading-the-docker-image"
            }}>{`Downloading the Docker Image`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#docker-compose"
            }}>{`Docker-Compose`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#docker-cli"
                }}>{`Docker CLI`}</a></li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#web-user-interface"
        }}>{`Web User Interface`}</a></li>
    </ul>
    {/* /TOC */}
    <p><a parentName="p" {...{
        "href": "https://frigate.video/"
      }}>{`Frigate NVR`}</a>{` - a complete and local NVR designed for Home Assistant with AI object detection. Uses OpenCV and Tensorflow to perform realtime object detection locally for IP cameras.`}</p>
    <h2 {...{
      "id": "installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#installation",
        "aria-label": "installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installation`}</h2>
    <p>{`Frigate is a Docker container that can be run on any Docker host including as a HassOS Addon. Note that a Home Assistant Addon is not the same thing as the integration. The integration is required to integrate Frigate into Home Assistant.`}</p>
    <h3 {...{
      "id": "dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#dependencies",
        "aria-label": "dependencies permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Dependencies`}</h3>
    <p>{`MQTT broker - Frigate requires an MQTT broker. If using Home Assistant, Frigate and Home Assistant must be connected to the same MQTT broker.`}</p>
    <h3 {...{
      "id": "storage",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#storage",
        "aria-label": "storage permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Storage`}</h3>
    <p>{`Frigate uses the following locations for read/write operations in the container. Docker volume mappings can be used to map these to any location on your host machine.`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`/media/frigate/clips`}</code>{`: Used for snapshot storage. In the future, it will likely be renamed from clips to snapshots. The file structure here cannot be modified and isn't intended to be browsed or managed manually.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`/media/frigate/recordings`}</code>{`: Internal system storage for recording segments. The file structure here cannot be modified and isn't intended to be browsed or managed manually.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`/media/frigate/frigate.db`}</code>{`: Default location for the sqlite database. You will also see several files alongside this file while frigate is running. If moving the database location (often needed when using a network drive at /media/frigate), it is recommended to mount a volume with docker at /db and change the storage location of the database to `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`/db/frigate.db`}</code>{` in the config file.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`/tmp/cache`}</code>{`: Cache location for recording segments. Initial recordings are written here before being checked and converted to mp4 and moved to the recordings folder.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`/dev/shm`}</code>{`: It is not recommended to modify this directory or map it with docker. This is the location for raw decoded frames in shared memory and it's size is impacted by the shm-size calculations below.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`/config/config.yml`}</code>{`: Default location of the config file.`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` -p /opt/frigate/storage`}</code></pre></div>
    <h3 {...{
      "id": "calculating-required-shm-size",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#calculating-required-shm-size",
        "aria-label": "calculating required shm size permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Calculating required shm-size`}</h3>
    <p>{`Frigate utilizes shared memory to store frames during processing. The default shm-size provided by Docker is 64m.`}</p>
    <p>{`The default shm-size of 64m is fine for setups with 2 or less 1080p cameras. If frigate is exiting with "Bus error" messages, it is likely because you have too many high resolution cameras and you need to specify a higher shm size.`}</p>
    <p>{`You can calculate the necessary shm-size for each camera with the following formula:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`width * height * `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1.5`}</span>{` * `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9`}</span>{` + `}<span parentName="code" {...{
            "className": "token number"
          }}>{`270480`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`/1048576 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`shm size `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` mb`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span></code></pre></div>
    <h3 {...{
      "id": "creating-a-config-file",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#creating-a-config-file",
        "aria-label": "creating a config file permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Creating a config file`}</h3>
    <h4 {...{
      "id": "configure-the-mqtt-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#configure-the-mqtt-server",
        "aria-label": "configure the mqtt server permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configure the MQTT server`}</h4>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`nano`}</span>{` /opt/frigate/config.yml`}</code></pre></div>
    <p>{`Frigate requires a functioning MQTT server. Start by adding the mqtt section at the top level in your config:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yml"
    }}><pre parentName="div" {...{
        "className": "language-yml"
      }}><code parentName="pre" {...{
          "className": "language-yml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`mqtt`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`host`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` <ip of your mqtt server`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` <username`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`password`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` <password`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`>`}</span></code></pre></div>
    <p>{`If using the Mosquitto Addon in Home Assistant, a username and password is required. For example:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yml"
    }}><pre parentName="div" {...{
        "className": "language-yml"
      }}><code parentName="pre" {...{
          "className": "language-yml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`mqtt`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`host`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 192.168.2.117
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` admin
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`password`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar`}</code></pre></div>
    <h4 {...{
      "id": "configure-your-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#configure-your-cameras",
        "aria-label": "configure your cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configure your Cameras`}</h4>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yml"
    }}><pre parentName="div" {...{
        "className": "language-yml"
      }}><code parentName="pre" {...{
          "className": "language-yml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`cameras`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`

  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`fullhd31`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# <------ Name the camera`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ffmpeg`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`inputs`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`path`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` rtsp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`//admin`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`instar@192.168.2.31`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`554/11 `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# <----- Update for your camera`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`roles`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` detect
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` rtmp
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`detect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1920`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# <---- update for your camera's resolution`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1080`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# <---- update for your camera's resolution`}</span>{`

  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`wqhd120`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# <------ Name the camera`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ffmpeg`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`inputs`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`path`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` rtsp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`//admin`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`instar@192.168.2.120`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`554/livestream/12 `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# <----- Update for your camera`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`roles`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` detect
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` rtmp
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`detect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1920`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# <---- update for your camera's resolution`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1080`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# <---- update for your camera's resolution`}</span></code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ff7b03bdf6e93e793968acdd4e4d98c0/dd104/Frigate_NVR_INSTAR_Cameras_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAA5klEQVQoz5WSCU7DMBBFfQ8yPyXO4iV23QZBF5qki+j9b4TGMUQF1ArpaezxzNcfWxbZ9ZJ9nLPrhc5HOo0z40D9IXEcb0qnkYae+l6galEa5A2eyp9kVeJ3KVYF3Brao1CQ5r8IXtyK9Y1jlE9RGhT6PlGsHdrwsPUPMWlPfs1uhcaziug5PnBWHu2K/WuLumUqy7eY9nJuzSO34oWCXrK+skxtUVool9Livrhp2Uf5dDSNOs3/3Rf9c2mS+OstBR3esVC03bF56Oh1g8ZxKg29vGHZwQbabHmioc9toNDRfs9fo9CfupBYyLRGuCEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ff7b03bdf6e93e793968acdd4e4d98c0/e4706/Frigate_NVR_INSTAR_Cameras_01.avif 230w", "/en/static/ff7b03bdf6e93e793968acdd4e4d98c0/d1af7/Frigate_NVR_INSTAR_Cameras_01.avif 460w", "/en/static/ff7b03bdf6e93e793968acdd4e4d98c0/7f308/Frigate_NVR_INSTAR_Cameras_01.avif 920w", "/en/static/ff7b03bdf6e93e793968acdd4e4d98c0/50d16/Frigate_NVR_INSTAR_Cameras_01.avif 1064w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ff7b03bdf6e93e793968acdd4e4d98c0/a0b58/Frigate_NVR_INSTAR_Cameras_01.webp 230w", "/en/static/ff7b03bdf6e93e793968acdd4e4d98c0/bc10c/Frigate_NVR_INSTAR_Cameras_01.webp 460w", "/en/static/ff7b03bdf6e93e793968acdd4e4d98c0/966d8/Frigate_NVR_INSTAR_Cameras_01.webp 920w", "/en/static/ff7b03bdf6e93e793968acdd4e4d98c0/8c558/Frigate_NVR_INSTAR_Cameras_01.webp 1064w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ff7b03bdf6e93e793968acdd4e4d98c0/81c8e/Frigate_NVR_INSTAR_Cameras_01.png 230w", "/en/static/ff7b03bdf6e93e793968acdd4e4d98c0/08a84/Frigate_NVR_INSTAR_Cameras_01.png 460w", "/en/static/ff7b03bdf6e93e793968acdd4e4d98c0/c0255/Frigate_NVR_INSTAR_Cameras_01.png 920w", "/en/static/ff7b03bdf6e93e793968acdd4e4d98c0/dd104/Frigate_NVR_INSTAR_Cameras_01.png 1064w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ff7b03bdf6e93e793968acdd4e4d98c0/c0255/Frigate_NVR_INSTAR_Cameras_01.png",
              "alt": "Frigate NVR - OpenCV, Tensorflow and MQTT",
              "title": "Frigate NVR - OpenCV, Tensorflow and MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "downloading-the-docker-image",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#downloading-the-docker-image",
        "aria-label": "downloading the docker image permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Downloading the Docker Image`}</h3>
    <p>{`The Docker image is being build on Github and can be downloaded from there - `}<a parentName="p" {...{
        "href": "https://github.com/blakeblackshear/frigate/pkgs/container/frigate"
      }}>{`Docker Images`}</a>{`. The - `}<em parentName="p">{`at the time of writing`}</em>{` - latest image can be downloaded with:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker pull ghcr.io/blakeblackshear/frigate:0.12.0-beta5`}</code></pre></div>
    <h3 {...{
      "id": "docker-compose",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#docker-compose",
        "aria-label": "docker compose permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Docker-Compose`}</h3>
    <p>{`Edit the following Docker-Compose file according to the container image and configuration path you have chosen above:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`nano`}</span>{` /opt/frigate/docker-compose.yml`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yml"
    }}><pre parentName="div" {...{
        "className": "language-yml"
      }}><code parentName="pre" {...{
          "className": "language-yml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`version`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"3.9"`}</span>{`
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`services`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`frigate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`container_name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` frigate
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`privileged`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# this may not be necessary for all setups`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`restart`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` unless`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`stopped
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` ghcr.io/blakeblackshear/frigate`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`0.12.0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`beta5
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`shm_size`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"64mb"`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# update for your cameras based on calculation above`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`devices`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` /dev/bus/usb`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/dev/bus/usb `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# passes the USB Coral, needs to be modified for other versions`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` /dev/apex_0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/dev/apex_0 `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# passes a PCIe Coral, follow driver instructions here https://coral.ai/docs/m2/get-started/#2a-on-linux`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` /dev/dri/renderD128 `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# for intel hwaccel, needs to be updated for your hardware`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`volumes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` /etc/localtime`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/etc/localtime`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`ro
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` /opt/frigate/config.yml`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/config/config.yml`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`ro
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` /opt/frigate/storage`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`/media/frigate
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` tmpfs `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Optional: 1GB of memory, reduces SSD/SD Card wear`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` /tmp/cache
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`tmpfs`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`size`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1000000000`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ports`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"5000:5000"`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"1935:1935"`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# RTMP feeds`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`FRIGATE_RTSP_PASSWORD`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"password"`}</span></code></pre></div>
    <h4 {...{
      "id": "docker-cli",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#docker-cli",
        "aria-label": "docker cli permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Docker CLI`}</h4>
    <p><strong parentName="p">{`If you can't use docker compose`}</strong>{`, you can run the container with this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker run -d `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  --name frigate `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  --restart`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`unless-stopped `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  --mount `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`tmpfs,target`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`/tmp/cache,tmpfs-size`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1000000000`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  --device /dev/bus/usb:/dev/bus/usb `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  --device /dev/dri/renderD128 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  --shm-size`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`64m `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  -v /opt/frigate/storage:/media/frigate `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  -v /opt/frigate/config.yml:/config/config.yml:ro `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  -v /etc/localtime:/etc/localtime:ro `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  -e `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`FRIGATE_RTSP_PASSWORD`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'password'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5000`}</span>{`:5000 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1935`}</span>{`:1935 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  ghcr.io/blakeblackshear/frigate:0.12.0-beta5`}</code></pre></div>
    <h2 {...{
      "id": "web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#web-user-interface",
        "aria-label": "web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Web User Interface`}</h2>
    <p>{`With the Docker container started head over to your web browser (I had an issue using Firefox - but Chrome worked) and open the following address:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://localhost:5000`}</code></pre></div>
    <p>{`You should now be able to see all your cameras:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c791fe47ad2d7815194c500cdf954e89/8802b/Frigate_NVR_INSTAR_Cameras_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36.08695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABlElEQVQY0zWPO0/CUACF78TEMwRYKgOTrmUgaoK/QRM3jTFhAV+TkQgqYo3Wqvj+AcafoQuTgRLr20GCFSVGoEV6Uei9vdcU47ed5Hw5OeD1o/n0Un2vdapN+tNGWrPZarU0DUINQgi/umiaVv9HUZR6vV6r1SCEQHz+zhX1fBFdl+ml+MClV3lhc+8gw/MbuxkhkYhPTI6Nj4/19faxbJBl2WAwGAqFAoFAMpkEDVXBGBFCKKW5vJhcWuEFIZVeTqWXE4mFeHx+ZHR4YLDfYrE4nU6bzWa3210uFwAgEokAtfGlI4wNUy5cSetb26dnZ0cnB5n9HY5b5Xl+em4mPBR2OBw9PX6GYTwej8/nAwDEYjGgqA2E8N9yNptdXErFpmYOj46FnS1ug1tf42ZnTdlqtTIM4/f73W631+sFAESjUaCoqmEYtIuqqje393f3j5+f1bdy+b1SKcvyS6l0JUnn5xeFQuG6iyRJoijKsgx0vUMp+ZMNQtuI/CD6rZtRx7Td7mCMMaGY0A4xC4RShA3zJyG/VnsYae5fZSIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c791fe47ad2d7815194c500cdf954e89/e4706/Frigate_NVR_INSTAR_Cameras_02.avif 230w", "/en/static/c791fe47ad2d7815194c500cdf954e89/d1af7/Frigate_NVR_INSTAR_Cameras_02.avif 460w", "/en/static/c791fe47ad2d7815194c500cdf954e89/7f308/Frigate_NVR_INSTAR_Cameras_02.avif 920w", "/en/static/c791fe47ad2d7815194c500cdf954e89/d5bb4/Frigate_NVR_INSTAR_Cameras_02.avif 1135w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c791fe47ad2d7815194c500cdf954e89/a0b58/Frigate_NVR_INSTAR_Cameras_02.webp 230w", "/en/static/c791fe47ad2d7815194c500cdf954e89/bc10c/Frigate_NVR_INSTAR_Cameras_02.webp 460w", "/en/static/c791fe47ad2d7815194c500cdf954e89/966d8/Frigate_NVR_INSTAR_Cameras_02.webp 920w", "/en/static/c791fe47ad2d7815194c500cdf954e89/5375b/Frigate_NVR_INSTAR_Cameras_02.webp 1135w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c791fe47ad2d7815194c500cdf954e89/81c8e/Frigate_NVR_INSTAR_Cameras_02.png 230w", "/en/static/c791fe47ad2d7815194c500cdf954e89/08a84/Frigate_NVR_INSTAR_Cameras_02.png 460w", "/en/static/c791fe47ad2d7815194c500cdf954e89/c0255/Frigate_NVR_INSTAR_Cameras_02.png 920w", "/en/static/c791fe47ad2d7815194c500cdf954e89/8802b/Frigate_NVR_INSTAR_Cameras_02.png 1135w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c791fe47ad2d7815194c500cdf954e89/c0255/Frigate_NVR_INSTAR_Cameras_02.png",
              "alt": "Frigate NVR - OpenCV, Tensorflow and MQTT",
              "title": "Frigate NVR - OpenCV, Tensorflow and MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And since we configured one of our cameras as an MQTT broker, Frigate now reports software states and events under `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`frigate/`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3a2ce55362acf77a401a3a08c04bf450/78415/Frigate_NVR_INSTAR_Cameras_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAB2UlEQVQoz12Q3Y6TQBiGuR43AuVn+GkXKAULw7TAfDMMA61tT/TAROOJ2WswUbObeOJNuLsab8IDN16Rwram8cmTyZy8ed8ZhX96oB8f4Ez64Ze8+b29/rlgr9E0s6a57qS6k0yGc9ANV1640lCiiH5fN13FZMUkbbp6UEK7XYt9RN5n8gfZfMvk3TPxle6/r7b3ZHNfdHe4u12KWwVoJTh0bdM2jFYlg5pDDbTCuEgzysQrf7p0/dT1U8ueW3Y0OjftyHYWipTycDjsdru+7znnjDEGrGkazsD1ZtvdyzgOg0vf9xz16RNNuziqXhgTVRFCcN4AQDVSliUhpCzL9Xpt23YrOKV1jvM0TTzP0zRNP2EYxtAsRENH6romhGCMCSFFgR3X67sNxjiO57PpFCGknzGEu66TbQtjGACG1QBCCCmljVDfd1mWLRaLKIp0TddOqKpq2rbSti3AY/ZYXtc1pZQQ4jiulJJznuVZkiRhGBqTiWVZ5ghCSGma42YAyJfLYOZfBjM/DGfhQEGKeRwHQeCOOAi5nocQGu4OUhhj/1pLEIRvyxXtcwzLzDANVVU1VdV1/XHq+ezhzack0GoNh7f03ZcXz998zvKrnExMUz/73v/4G/4Di82/iN15Zq0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3a2ce55362acf77a401a3a08c04bf450/e4706/Frigate_NVR_INSTAR_Cameras_03.avif 230w", "/en/static/3a2ce55362acf77a401a3a08c04bf450/d1af7/Frigate_NVR_INSTAR_Cameras_03.avif 460w", "/en/static/3a2ce55362acf77a401a3a08c04bf450/7f308/Frigate_NVR_INSTAR_Cameras_03.avif 920w", "/en/static/3a2ce55362acf77a401a3a08c04bf450/f4ff4/Frigate_NVR_INSTAR_Cameras_03.avif 1207w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3a2ce55362acf77a401a3a08c04bf450/a0b58/Frigate_NVR_INSTAR_Cameras_03.webp 230w", "/en/static/3a2ce55362acf77a401a3a08c04bf450/bc10c/Frigate_NVR_INSTAR_Cameras_03.webp 460w", "/en/static/3a2ce55362acf77a401a3a08c04bf450/966d8/Frigate_NVR_INSTAR_Cameras_03.webp 920w", "/en/static/3a2ce55362acf77a401a3a08c04bf450/459db/Frigate_NVR_INSTAR_Cameras_03.webp 1207w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3a2ce55362acf77a401a3a08c04bf450/81c8e/Frigate_NVR_INSTAR_Cameras_03.png 230w", "/en/static/3a2ce55362acf77a401a3a08c04bf450/08a84/Frigate_NVR_INSTAR_Cameras_03.png 460w", "/en/static/3a2ce55362acf77a401a3a08c04bf450/c0255/Frigate_NVR_INSTAR_Cameras_03.png 920w", "/en/static/3a2ce55362acf77a401a3a08c04bf450/78415/Frigate_NVR_INSTAR_Cameras_03.png 1207w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3a2ce55362acf77a401a3a08c04bf450/c0255/Frigate_NVR_INSTAR_Cameras_03.png",
              "alt": "Frigate NVR - OpenCV, Tensorflow and MQTT",
              "title": "Frigate NVR - OpenCV, Tensorflow and MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      